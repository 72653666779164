<template>
  <div class="auth-content">
    <div
      v-if="selectMerchant"
      class="back-button"
    >
      <back-button
        @click.native="selectMerchant = false"
      />
    </div>

    <v-row
      v-if="!selectMerchant"
      class="auth-content-box"
    >
      <v-col class="auth-content-box-wrapper">
        <div class="header-box">
          <v-row>
            <v-col
                cols="auto"
              class="header inactive"
              @click="toRoute ('/login')"
            >
              {{ $t('label.login') }}
              
            </v-col>
            <v-col
                cols="auto"
              class="header inactive"
              @click="toRoute ('/registration')"
            >
                      {{ $t('label.registration') }}
              
            </v-col>
            <v-col
                cols="auto"
              class="header"
              @click="toRoute ('/login/demo')"
            >
                      {{ $t('label.demo') }}
              
            </v-col>
          </v-row>
        </div>
        <v-form
          ref="form"
          v-model="valid"
          class="auth-form"
        >
          <v-row>
            <v-col>
              <BaseMasterFieldBlock
                class="mt-0"
                title="Сфера бизнеса"
              >
                <span slot="description">Выберите сферу вашего бизнеса</span>
                <template v-slot:input>
                  <v-select
                    v-model="selectedDemoLogin"
                    :items="demoLogins"
                    :rules="[
                      v => !!v || 'Выберите сферу'
                    ]"
                    item-value="id"
                    item-text="label"
                    return-object
                    outlined
                  />
                </template>
              </BaseMasterFieldBlock>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                style="width: 100%;"
                :loading="loading"
                @click="submit()"
              >
                <span
                  class="iconify"
                  style="margin-right: 8px;"
                  data-icon="ion:log-out-outline"
                  data-inline="false"
                />
                Войти в демо аккаунт
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <select-merchant
      v-else
      :merchants="merchants"
      @select="submitMerchant"
    />
    <vue-recaptcha
      ref="recaptcha"
      size="invisible"
      :sitekey="$config.app.RECAPTCHA_SITE_KEY"
      :load-recaptcha-script="true"
      @verify="login"
      @expired="onCaptchaExpired"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import BackButton from '@/views/auth/components/BackButton'
  import VueRecaptcha from 'vue-recaptcha'

  export default {
    components: {
      BackButton,
      VueRecaptcha,
      SelectMerchant: () => import('@/views/auth/components/SelectMerchant'),
    },
    data () {
      return {
        valid: false,
        loading: false,
        selectMerchant: false,
        selectedDemoLogin: null,
      }
    },
    computed: {
      ...mapGetters('auth/auth', [
        'merchants',
        'merchant_id',
        'merchant',
        'device',
      ]),
      demoLogins () {
        if (process.env.NODE_ENV === 'development' && false) {
          return [
            { id: 1, label: 'Стоматология', name: 'СтоматогПлюс', login: 'demo@pluscards.ru', password: 'demo2021' },
            { id: 2, label: 'Магазин одежды', name: 'In love', login: 'demo@pluscards.ru', password: 'demo2021' },
            { id: 3, label: 'Ветеринарная клиника', name: 'Шанти', login: 'demo@pluscards.ru', password: 'demo2021' },
            { id: 4, label: 'Развивающий центр', name: 'Академия детства', login: 'demo@pluscards.ru', password: 'demo2021' },
            { id: 5, label: 'Зоомагазин', name: 'Mr. Cat', login: 'demo@pluscards.ru', password: 'demo2021' },
            { id: 6, label: 'Кофейня', name: 'Retro Coffee', login: 'demo@pluscards.ru', password: 'demo2021' },
            { id: 7, label: 'Автосервис', name: 'Prestige Service', login: 'demo@pluscards.ru', password: 'demo2021' },
          ]
        } else {
          return [
            { id: 1, label: 'Стоматология', name: 'СтоматогПлюс', login: 'natasha27.04.99+89@mail.ru', password: 'cat123456' },
            { id: 2, label: 'Магазин одежды', name: 'In love', login: 'Kurdyukova98+51@bk.ru', password: 'xxxx1234' },
            { id: 3, label: 'Ветеринарная клиника', name: 'Шанти', login: 'natasha27.04.99+131@mail.ru', password: 'cat123456' },
            { id: 4, label: 'Развивающий центр', name: 'Академия детства', login: 'Kurdyukova98+13@bk.ru', password: 'zzzz1234' },
            { id: 5, label: 'Зоомагазин', name: 'Mr. Cat', login: 'clientpluscards+8@gmail.com', password: 'zzzz1234' },
            { id: 6, label: 'Кофейня', name: 'Retro Coffee', login: 'Kurdyukova98+118@bk.ru', password: 'xxxx1234' },
            { id: 7, label: 'Автосервис', name: 'Prestige Service', login: 'Kurdyukova98+54@bk.ru', password: 'xxxx1234' },
            { id: 8, label: 'Фитнес-клуб', name: 'Аллигатор', login: 'Kurdyukova98+70@bk.ru', password: 'zzzz1234' },
            { id: 9, label: 'Школа китайского языка', name: 'Вальянс', login: 'clientpluscards+007@gmail.com', password: 'cat12345661' },           
          ]
        }
      },
    },
    mounted () {
      this.$store.dispatch('auth/auth/InitDevice')
      this.selectedDemoLogin = this.demoLogins[0]
      this.$store.commit('auth/auth/SET_MERCHANT_ID', null)
    },
    beforeDestroy () {

    },
    methods: {
      recaptchaExecute () {
        this.$refs.recaptcha.execute()
      },
      submitMerchant (merchantId) {
        this.$store.commit('auth/auth/SET_MERCHANT_ID', merchantId)
        this.recaptchaExecute()
      },
      submit () {
        if (!this.$refs.form.validate()) return
        this.recaptchaExecute()
      },
      onCaptchaExpired () {
        this.$refs.recaptcha.reset()
      },
      toRoute (path) {
        if (this.$route.path !== path) this.$router.push(path)
      },
      async login (recaptchaToken) {
        if (!this.selectedDemoLogin || this.loading) return

        const user = {
          email: this.selectedDemoLogin.login,
          password: this.selectedDemoLogin.password,
          device_id: this.device.id,
          device_token: this.device.token,
          device_type: this.device.type,
          recaptcha_token: recaptchaToken,
          merch_id: this.merchant_id,
        }
        console.log(user)
        try {
          this.loading = true
          await this.$store.dispatch('auth/email/login', user)
          // выбор мерчанта для логина или сразу логин
          if (this.merchants.length > 1) {
            this.selectMerchant = true
            this.valid = false
          } else {
            this.toRoute('/')
          }
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>

<style lang="sass" scoped>
@import "~@/styles/auth"
</style>
